import React, { DOMAttributes, useMemo } from 'react';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import { t } from '@lingui/macro';
import { ConnectDropTarget } from 'react-dnd';
import { AriaCheckboxProps, VisuallyHidden } from 'react-aria';

import PreviewImage from 'src/content/files/common/PreviewImage';
import { useThumbnailSelector } from 'src/content/common/hooks';
import { useSelector } from 'react-redux';
import {
  getConsentStatusColor,
  getLocalizedConsentStatus,
  getConsentStatusByFile,
} from 'src/content/common/utils';
import { isIOS } from '../utils/browser.utils';
import ItemLabel from './ItemLabel';
import Checkbox from '~inputs/Checkbox';
import Typography from '~misc/Typography';
import { appearForwards } from '~utils/animations.utils';
import { FolderContentIcon, LikedIcon, WorkspaceIcon } from '~misc/icons';
import { Chip } from '~common/items/Chips';
import { File } from '~common/content.types';
import { getLangValue } from '~common/app.utils';
import { reformatDateString } from '~common/utils/date.utils';
import { ConsentStatus } from '~common/content.constants';

// TODO: typing

interface DndRefs {
  drag: React.Ref<any>;
  drop: ConnectDropTarget;
  preview?: React.Ref<any>;
}

export interface ItemThumbnailProps {
  itemId: string;
  item?: File;
  name: string;
  /** An additional string to be shown on top of the name */
  caption?: string;
  altText?: string;
  description?: string;
  imageUrl?: string;
  fallbackUrl?: string;
  format?: string | null;
  childrenCount?: number | string;
  variant?: 'small' | 'medium' | 'large' | 'elevated';
  itemType?: 'any' | 'folder' | 'cart';
  expandable?: boolean;
  animate?: boolean;
  index: any;
  selected?: boolean;
  onSelect?: any;
  onUnselect?: any;
  indeterminate?: boolean;
  checked?: boolean;
  setItemChecked?: (id: string, checked: boolean) => void;
  dropHover?: boolean;
  dndRefs?: DndRefs;
  showChevron?: boolean;
  showCheckbox?: boolean;
  checkboxProps?: AriaCheckboxProps;
  textContent?: string;
  actions?: any;
  /** Props applied to the wrapper div */
  wrapperProps?: {
    onClick?: () => void;
    tabIndex?: number;
    className?: string;
  };
  inlinePreviewProps?: DOMAttributes<HTMLElement> & React.AriaAttributes;
  indicateSynkka?: boolean;
  mode?: string;
  isLink?: boolean;
  chipLabels?: string[];
}

const ItemThumbnail = ({
  itemId,
  item,
  name,
  caption,
  altText = '',
  imageUrl,
  fallbackUrl,
  childrenCount,
  variant = 'medium',
  itemType = 'any',
  expandable = false,
  animate = false,
  selected,
  onSelect,
  onUnselect,
  indeterminate,
  checked,
  setItemChecked,
  dropHover,
  dndRefs,
  showChevron,
  showCheckbox,
  checkboxProps,
  textContent,
  format,
  actions,
  wrapperProps,
  inlinePreviewProps,
  indicateSynkka,
  mode,
  isLink,
  chipLabels,
}: ItemThumbnailProps) => {
  const showCount = childrenCount !== undefined && Number(childrenCount) > 0;

  const share = useSelector(state => state.app.share);

  const isDropTarget =
    itemType === 'folder' && !isLink && Boolean(dndRefs?.drop);

  const centeredCheckbox = variant === 'small';

  const customerMetaFields = useSelector(
    state => state.commonContent.customerMetaFields
  );

  const { renderThumbnailSelector, imageUrl: thumbnailImage } =
    useThumbnailSelector(item);

  const thumbnailSelector = renderThumbnailSelector();
  const showThumbnailSelector = Boolean(thumbnailSelector);

  const currentImage = thumbnailImage ?? imageUrl;

  // Folders don't have a file extension -> shorter last chars
  const sliceIndex = showCount ? -4 : -8;
  const textBeginning = name ? name.slice(0, sliceIndex) : '';
  const textEnd = name ? name.slice(sliceIndex) : '';

  const handleClick = () => {
    if (selected) onUnselect?.();
    else onSelect?.();
    wrapperProps?.onClick?.();
  };

  const handleKeyDown = e => {
    if (e.target !== e.currentTarget) return;
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <ImageWrapper
      {...wrapperProps}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      variant={variant}
      itemType={itemType}
      animate={animate}
      selected={!expandable && !!selected}
      hover={dropHover}
      hoverOpacity={centeredCheckbox && !showChevron}
      aria-label={`${format || ''} ${name} ${altText ?? ''}`}
      data-drop-target={isDropTarget}
    >
      {onSelect && onUnselect && (
        <VisuallyHidden>
          <button {...inlinePreviewProps} onClick={handleClick}>
            {!selected ? t`Open ${name}` : t`Close ${name}`}
          </button>
        </VisuallyHidden>
      )}
      {itemType === 'folder' && (
        <FolderMarker>
          <div className="folder-marker-bar" />
          <div className="folder-marker-side" />
        </FolderMarker>
      )}
      <div
        style={{ width: '100%', height: '100%' }}
        ref={isDropTarget ? e => dndRefs?.drop(e) : undefined}
      >
        <div
          style={{ width: '100%', height: '100%' }}
          ref={dndRefs && dndRefs.drag}
        >
          <ThumbContent>
            {textContent && (
              <ThumbnailTextWrapper>{textContent}</ThumbnailTextWrapper>
            )}
            {!textContent && variant !== 'small' && (
              <ThumbnailImageWrapper
                variant={variant}
                itemType={itemType}
                showThumbnailSelector={showThumbnailSelector}
              >
                {chipLabels && (
                  <LabelWrapper>
                    {chipLabels.map(text => (
                      <Chip small label={text} key={text} selected={selected} />
                    ))}
                  </LabelWrapper>
                )}
                {itemType === 'folder' && !currentImage ? (
                  <FolderContentIcon />
                ) : itemType === 'cart' ? (
                  <StyledWorkspaceIcon />
                ) : (
                  <Tooltip title={altText} enterDelay={1000}>
                    <ThumbnailImage
                      src={currentImage ?? ''}
                      fallbackSrc={fallbackUrl}
                      aria-label={altText}
                    />
                  </Tooltip>
                )}

                {thumbnailSelector}
                <ConsentStatusInfo
                  status={getConsentStatusByFile(item, share)}
                />
              </ThumbnailImageWrapper>
            )}
            {variant !== 'small' && (
              <StatusIndicatorWrapper>
                {item?.isLikedByUser && (
                  <StyledLikedIcon color="primary" fontSize="inherit" />
                )}
                {indicateSynkka && (
                  <SynkkaIndicator
                    item={item}
                    customerMetaFields={customerMetaFields}
                  />
                )}
              </StatusIndicatorWrapper>
            )}

            <Tooltip title={name}>
              <Caption
                variant={variant}
                showThumbnailSelector={showThumbnailSelector}
              >
                {caption && (
                  <AdditionalCaption name={caption} variant="caption" />
                )}
                <CaptionNameRow>
                  <CaptionNameWrapper>
                    <CaptionName
                      isLink={item?.node.isLink}
                      isShared={item?.node.isShared ?? undefined}
                      inCart={item?.node.inCart || item?.node.inShoppingCart}
                      isSearch={mode === 'search'}
                      name={textBeginning}
                      variant="caption"
                    />
                    <CaptionNameLast variant="caption">
                      {textEnd}
                    </CaptionNameLast>
                  </CaptionNameWrapper>
                  {showCount && (
                    <CaptionCount variant="caption">
                      {childrenCount != null && `(${childrenCount})`}
                      {!showChevron ? null : selected ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </CaptionCount>
                  )}
                </CaptionNameRow>
              </Caption>
            </Tooltip>
            {showCheckbox && (
              <StyledCheckbox
                title={`${t`Select`} ${name}`}
                isIOS={isIOS()}
                centered={centeredCheckbox}
                checked={checked || checkboxProps?.isSelected}
                indeterminate={indeterminate}
                onClick={e => {
                  e.persist();
                  e.stopPropagation();
                }}
                onChange={e => {
                  setItemChecked && setItemChecked(itemId, e.target.checked);
                }}
                {...checkboxProps}
              />
            )}
            <StyledActions
              className="thumbnail-actions"
              onClick={event => {
                event.stopPropagation();
              }}
            >
              {actions}
            </StyledActions>
            <LeftCorner>
              <ConsentStatusIndicator
                status={getConsentStatusByFile(item, share)}
              />
              {format && variant !== 'small' && (
                <FormatInfo>
                  <Typography variant="caption">{format}</Typography>
                </FormatInfo>
              )}
            </LeftCorner>
          </ThumbContent>
        </div>
      </div>
      {expandable && selected && (
        <SelectedMarker>
          <SelectedMarkerTringle variant={showCount ? 'small' : 'large'} />
        </SelectedMarker>
      )}
    </ImageWrapper>
  );
};

const SynkkaIndicator = ({ item, customerMetaFields }) => {
  const isSynkkaError = item?.synkkaErrorMessage;
  return (
    <Tooltip
      title={<SynkkaInfo item={item} customerMetaFields={customerMetaFields} />}
    >
      <SynkkaDiv error={isSynkkaError}>
        <Typography variant="caption">S</Typography>
      </SynkkaDiv>
    </Tooltip>
  );
};

const SynkkaInfo = ({ item, customerMetaFields }) => {
  // util for parsing meta
  const getMetaValue = (key: string): string | undefined => {
    const metaId = customerMetaFields.find(m =>
      Object.values(m.namesByLang).some(val => val === key)
    )?.id;
    if (!metaId) return undefined;
    return getLangValue(item.metaValuesById[metaId as number]);
  };

  const { sender, sendDate, error } = useMemo(() => {
    const sender = getMetaValue('synkka sender');
    const sendDate = reformatDateString(getMetaValue('synkka send date') ?? '');
    const error = item?.synkkaErrorMessage;

    return { sender, sendDate, error };
  }, [item, customerMetaFields]);

  if (!sender && !sendDate && !error) return null;

  return (
    <div>
      <Typography>{t`Synkka information`}</Typography>
      {sender && (
        <div>
          <SynkkaInfoTag>{t`Sender`}: </SynkkaInfoTag>
          <span>{sender}</span>
        </div>
      )}
      {sendDate && (
        <div>
          <SynkkaInfoTag>{t`Send date`}: </SynkkaInfoTag>
          <span>{sendDate}</span>
        </div>
      )}
      {error ? (
        <div>
          <SynkkaInfoTag error>{t`Synkka error`}:</SynkkaInfoTag>
          <span> {error}</span>
        </div>
      ) : null}
    </div>
  );
};

const ConsentStatusInfo = ({ status }: { status?: ConsentStatus }) => {
  if (status) {
    return (
      <ConsentStatusLabel status={status}>
        <Typography variant="caption">
          {getLocalizedConsentStatus(status)}
        </Typography>
      </ConsentStatusLabel>
    );
  }
  return <></>;
};

interface VariantProps {
  variant: string;
}

interface CaptionProps {
  variant: string;
  showCount?: boolean;
  showThumbnailSelector?: boolean;
}

const Caption = styled.div<CaptionProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ variant, showThumbnailSelector }) =>
    variant !== 'small' ? (showThumbnailSelector ? '2px' : '6px') : '4px'};
`;

const CaptionNameRow = styled.div`
  display: flex;
`;

const CaptionNameWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const CaptionName = styled(ItemLabel)`
  && {
    flex: 1;
    white-space: pre;
    // Safari is a POS, so we need 'word-wrap' here
    // https://stackoverflow.com/a/56961220
    word-wrap: normal;
  }
`;

const AdditionalCaption = styled(ItemLabel)`
  margin-bottom: -2px;
`;

const CaptionNameLast = styled(Typography)`
  white-space: pre-wrap;
`;

const CaptionCount = styled(Typography)`
  && {
    display: flex;
    margin-left: 4px;
    align-items: center;
    flex-basis: 50px;
    flex-shrink: 0;

    svg {
      margin-top: 1px;
    }
  }
`;

const FormatInfo = styled.div`
  && {
    visibility: hidden;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 2px;
    min-width: 16px;
    padding: 1px 3px 0;
    color: white;
    .MuiTypography-caption {
      line-height: 1;
    }
    .MuiIconButton-label {
      overflow: hidden;
    }
    text-transform: uppercase;
  }
`;

const LabelWrapper = styled.div`
  left: 0px;
  top: 0px;
  position: absolute;
  z-index: 1;
  display: flex;
  gap: 0.25rem 0.5rem;
  flex-wrap: wrap;
`;

const StatusIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 34px;
  left: 7px;
  font-size: 19px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const SynkkaDiv = styled.div<{ error?: boolean }>`
  background-color: ${p =>
    p.error ? p.theme.palette.error.main : p.theme.palette.primary.main};
  color: white;
  border-radius: 100%;
  text-align: center;
  width: 0.9em;
  height: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const ConsentStatusLabel = styled.div<{ status?: ConsentStatus }>`
  && {
    visibility: hidden;
    left: 4px;
    bottom: 4px;
    position: absolute;
    background-color: ${p =>
      p.status ? getConsentStatusColor(p.status, p.theme) : 'none'};
    border-radius: 2px;
    min-width: 16px;
    padding: 1px 3px 0;
    color: white;
    .MuiTypography-caption {
      line-height: 1;
    }
    .MuiIconButton-label {
      overflow: hidden;
    }
    text-transform: uppercase;
  }
`;

const ConsentStatusIndicator = styled.div<{ status?: ConsentStatus }>`
  display: ${p => (p.status ? 'visible' : 'none')};
  background-color: ${p =>
    p.status ? getConsentStatusColor(p.status, p.theme) : 'none'};
  border-radius: 100%;
  width: 1.1em;
  height: 1.1em;
  margin-right: 6px;
`;

const LeftCorner = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  display: flex;
  align-items: center;
`;

const StyledLikedIcon = styled(LikedIcon)`
  display: block;
`;

const SynkkaInfoTag = styled.span<{ error?: boolean }>`
  display: inline-block;
  padding: 0px 4px;
  margin-left: -4px;
  background-color: ${p => (p.error ? p.theme.palette.error.main : 'none')};
  color: ${p => p.theme.palette.common.white};
  border-radius: ${p => p.theme.shape.borderRadius}px;
  font-weight: bold;
`;

interface ImageWrapperProps
  extends Omit<React.ComponentProps<typeof Paper>, 'variant'> {
  variant: 'small' | 'medium' | 'large' | 'elevated';
  itemType: string;
  selected: boolean;
  animate: boolean;
  hover?: boolean;
  hoverOpacity?: boolean;
}

// TODO: Remove type "any" after type error regarding property "style" fixed
// For some godforsaken reason we need to forwardRef here even though we
// never pass a ref to this component, otherwise we get errors in console

const ImageWrapper = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, ImageWrapperProps>(
    ({ variant, animate, hover, hoverOpacity, selected, ...rest }, ref) => (
      <Paper {...rest} ref={ref} />
    )
  )
)<ImageWrapperProps>`
  position: relative;
  padding: 6px;

  display: inline-block;
  margin: 0;
  width: 100%;
  max-width: 364px;

  ${({ itemType }) =>
    itemType === 'folder' &&
    `
    margin-top: 18px;
    border-top-left-radius: 0px !important;
  `};

  border-color: ${({ selected, theme }) =>
    selected
      ? (theme.palette as any).primary.main
      : (theme.palette as any).border.card} !important;

  vertical-align: top;
  ${({ animate }: { animate: boolean }) => (animate ? appearForwards : null)}

  // if not mobile / tablet, show folder / file controls on hover
  // in media query to prevent iOS from seeing hover rules on clickable elements
  ${p => p.theme.breakpoints.up('md')} {
    cursor: pointer;
    &:hover,
    &:focus,
    &:focus-within,
    &.focus-visible {
      outline: none;
      border-color: ${({ theme }) =>
        (theme.palette as any).primary.main} !important;

      .MuiCheckbox-root {
        visibility: visible !important;
      }
      .thumbnail-actions {
        visibility: visible !important;
      }

      ${FormatInfo} {
        visibility: visible;
      }

      ${ConsentStatusLabel} {
        visibility: visible;
      }
    }
  }

  // apply above hover styles / file actions always on mobile
  ${p => p.theme.breakpoints.down('md')} {
    outline: none;
    border-color: ${({ theme }) =>
      (theme.palette as any).primary.main} !important;

    .MuiCheckbox-root {
      visibility: visible !important;
    }
    .thumbnail-actions {
      visibility: visible !important;
    }

    ${FormatInfo} {
      visibility: visible;
    }

    ${ConsentStatusLabel} {
      visibility: visible;
    }
  }

  &:hover,
  &:focus {
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
  ${p =>
    p.hover &&
    `
    && {
      box-shadow: ${p.theme.shadows[4]};
      border-color: ${p.theme.palette.primary.main} !important;
    }
  `}

  ${({ hoverOpacity }: any) =>
    hoverOpacity &&
    `&:hover ${Caption}:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 1) 85%
    );
    pointer-events: none;
  }`}
`;

const FolderMarker = styled.div`
  width: 100%;
  height: 18px;
  position: absolute;
  top: -19px;
  left: -1px;
  overflow: hidden;

  .folder-marker-bar {
    z-index: 2;
    position: relative;
    border: 1px solid ${({ theme }) => (theme.palette as any).border.card};
    border-top-left-radius: 4px;
    border-right: none;
    border-bottom: none;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    width: 64px;
    height: 100%;
  }

  .folder-marker-side {
    z-index: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-right: 1px solid ${({ theme }) => (theme.palette as any).border.card};
    border-top: 1px solid ${({ theme }) => (theme.palette as any).border.card};
    border-radius: 15px;
    top: 6px;
    left: 31px;
    width: 60px;
    height: 60px;
    transform: rotate(-35deg);
  }
`;

const SelectedMarker = styled.div`
  width: 100%;
  position: absolute;
  bottom: -18px;
  left: 0;
`;

const SelectedMarkerTringle = styled.div<VariantProps>`
  width: 0;
  height: 0;
  margin: 0 auto;

  ${({ variant }) =>
    variant !== 'large' &&
    `
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #C0C0C0;
  `}

  ${({ theme, variant }) =>
    variant === 'large' &&
    `
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${(theme.palette.background as any).dark};
  `}
`;

// border-bottom: 8px solid #C0C0C0;

const ThumbContent = styled.span`
  background-color: #29323b;
`;

interface ThumbnailProps {
  variant?: string;
  itemType?: string;
  showThumbnailSelector?: boolean;
}

const ThumbnailImageWrapper = styled.div<ThumbnailProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ variant, itemType, showThumbnailSelector }) =>
    variant === 'large'
      ? '154px'
      : itemType === 'folder'
      ? '96px'
      : `${showThumbnailSelector ? '118px' : '114px'}`};
  position: relative;
  width: 100%;
  background-color: ${({ theme }: any) => theme.palette.background.thumbnail};
`;

const ThumbnailTextWrapper = styled(ThumbnailImageWrapper)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  white-space: pre-line;
`;

// width: ${(props: { width: number }) => props.width}px;
const ThumbnailImage = styled(PreviewImage)`
  width: 100%;
  height: 100%;
`;

const StyledActions = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  visibility: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

type CheckboxProps = React.ComponentProps<typeof Checkbox> & {
  isIOS?: boolean;
  centered?: boolean;
};
// We need to forwardRef, otherwise we get errors
const StyledCheckbox: any = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLButtonElement, CheckboxProps>(
    ({ isIOS, centered, ...rest }, ref) => <Checkbox {...rest} ref={ref} />
  )
)`
  && {
    position: absolute;
    top: ${({ centered }: any) => (centered ? '50%' : '5px')};
    right: -4px;
    min-width: 16px;
    height: 24px;
    color: ${({ checked, indeterminate, theme }) =>
      checked || indeterminate ? theme.palette.secondary.main : undefined};

    ${({ centered }: any) => centered && 'transform: translateY(-50%);'}

    visibility: ${({ checked, indeterminate }) =>
      checked || indeterminate ? 'visible' : 'hidden'};

    /* NOTE: Show checbox on small non-iOS devices even though they can hover
       just in case hover does not work well */
    ${({ isIOS }: any) =>
      !isIOS &&
      `
      @media (hover: none), (max-width: 1199px) {
        visibility: visible;
      }
      `}

    .MuiIconButton-label {
      overflow: hidden;
    }
  }
`;

const StyledWorkspaceIcon = styled(WorkspaceIcon)`
  && {
    width: 80px; // magic numbers from folder icon dimensions
    height: 80px;
    color: ${p => p.theme.palette.primary.main};
  }
`;

export default ItemThumbnail;
