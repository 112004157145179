import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { Cropper } from './Cropper';
import { Use, Crop, Sizes } from './types';
import fileContentApi from './fileContent.api';
import cropperStyles from './cropperStyles.jsx';
import { BREAKPOINTS } from '~common/utils/styled.utils';

interface Props {
  use?: Use;
  crop: Crop;
  size: { x: number; y: number };
  ratio?: number;
  originalSize: { x: number; y: number };
  onSetCrop: (sizes: Sizes) => void;
  onAdjustCropDimension: (dimensions: { x: number; y: number }) => void;
  nodeId?: string;
  allowOverflow?: boolean;
  previewLoading?: boolean;
}

export default function Picture({
  nodeId,
  crop,
  size,
  originalSize,
  use,
  onSetCrop,
  allowOverflow,
  previewLoading,
  ...props
}: Props) {
  const fixedRatio =
    !!props.ratio || !!(use && use.proportions && use.proportions.x);
  const ratio = fixedRatio
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      props.ratio ?? use!.proportions!.x / use!.proportions!.y
    : null;
  let optionalProps: any = fixedRatio ? { fixedRatio, ratio } : { fixedRatio };

  if (size.x && size.y) {
    // optionalProps.width = size.x ? parseFloat(size.x) : null;
    optionalProps.width = size.x ?? null;
    // optionalProps.height = size.y ? parseFloat(size.y) : null;
    optionalProps.height = size.y ?? null;
  }

  if (crop.x1 || crop.y1) {
    optionalProps = {
      ...optionalProps,
      // originX: parseFloat(crop.x1),
      originX: crop.x1,
      // originY: parseFloat(crop.y1),
      originY: crop.y1,
    };
  }

  if (previewLoading)
    return (
      <PictureWrapper>
        <CircularProgress color="primary" />
      </PictureWrapper>
    );

  // Set ratio as the key for Cropper to force a re-render when changing use
  return (
    <PictureWrapper $allowOverflow={allowOverflow}>
      <span>
        <FormattedMessage id="file.cropOriginalImageSize" /> {originalSize.x} ×{' '}
        {originalSize.y} <FormattedMessage id="file.cropPixels" />.
      </span>
      <CropperContainer>
        <Cropper
          key={use?.id}
          alt="preview"
          src={fileContentApi.getLink({ id: nodeId, type: 'preview' })}
          onChange={onSetCrop}
          styles={cropperStyles}
          allowNewSelection={false}
          allowOverflow={allowOverflow}
          {...optionalProps}
        />
      </CropperContainer>
    </PictureWrapper>
  );
}

const PictureWrapper = styled.div<{ $allowOverflow?: boolean }>`
  flex: 0 1 auto; /* The Cropper component doesn't behave well if grown */
  display: inline-block;
  padding: 16px 0; /* The dots can go outside of bounds without this */
  padding-bottom: 10px;
  max-height: inherit;
  box-sizing: content-box;
  max-width: 100%;

  ${props => props.$allowOverflow && 'padding: 50px; padding-top: 40px;'}

  &:only-child {
    margin: auto;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 8px;
  }
`;

const CropperContainer = styled.div`
  padding-top: 10px;
`;
