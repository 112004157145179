import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import exifr from 'exifr';

import {
  DESCRIPTION_FIELD_ID,
  INSTRUCTION_FIELD_ID,
  PROPERTY_VALIDITY_PERIOD,
  PROPERTY_VALIDITY_PERIOD_START,
  PROPERTY_VALIDITY_PERIOD_END,
  isBasicFieldWithoutLanguageSupport,
  PROPERTY_MARKED_FOR_ARCHIVING,
} from '../../common/utils';
import {
  FileToAdd,
  IntermediateFileToAdd,
  FileToEdit,
  LayoutConfig,
  ImageMetaToCustomerMeta,
  ImageMetaFieldFormat,
} from './add.types';
import { useBackgroundUploadContext } from './BackgroundUploadProvider';
import {
  queryExistingFileId,
  useGetFolderQuery,
  readFile,
  useLazyGetExistingFileQuery,
  useCreateFileMultipartMutation,
  invalidateFile,
  useUpdateFileMultipartMutation,
  useUploadFileChunkMutation,
} from '~common/content.api';
import {
  MetaData,
  MetaField,
  File,
  isDropdownMetaField,
  isTreeMetaField,
  UploadFile,
} from '~common/content.types';
import { UploadStatus } from '~common/content.constants';
import {
  flattenMetaValuesById,
  getMetaValuesById,
  getMetaFieldKey,
  validateNode,
  flattenDescriptionsByLang,
  useCustomerMetaFields,
  flattenLocalizedObject,
} from '~common/content.utils';
import { filterUndefined, mapKeys, sha1 } from '~common/utils/fn.utils';
import { FieldError, isErroneousField } from '~common/utils/form.utils';
import { LanguageCode, getLangValue } from '~common/app.utils';
import { Lang } from '~common/utils/i18n';
import { TranslatedData } from '~common/common.types';
import { executeBatchedOperations } from '~common/utils/io.utils';
import { UploadMode, UserRights } from '~common/app.types';
import { useActions } from '~common/utils/hooks.utils';

export const getCustomMetaFieldKey = (id: string, lang: string) =>
  `custom:meta-field-${id}_${lang}`;

export const getMetaFieldId = (key: string) =>
  key.match(/^custom:meta-field-(.*?)_(.*?)$/)?.[1];

/** Wrapper for handles customer meta fields or static description, instruction field */
export const getCustomMetaKey = (id: string, lang: string) => {
  id = id.trim();
  if (isBasicFieldWithoutLanguageSupport(id)) {
    return id;
  }
  return isNaN(Number(id)) ? `${id}_${lang}` : getMetaFieldKey(id, lang);
};

/** Converts metaById values to values understood by the Api */
export const convertMetaByIdOut = (
  metaById: Record<string, MetaData | undefined>,
  metaFields: MetaField[] = []
) =>
  Object.keys(metaById).reduce((a, c) => {
    const field = metaFields.find(field => field.id === getMetaFieldId(c));
    const data = metaById[c];
    if (field && isTreeMetaField(field) && Array.isArray(data)) {
      a[c] = data.join(';');
      return a;
    }
    a[c] = Array.isArray(data)
      ? data.map(x => sha1(x)).join(' ')
      : data instanceof Date
      ? format(data, 'dd.MM.yyyy') // The old UI understands dates in 'dd.MM.yyyy' format
      : data?.toString() ?? null;
    return a;
  }, {} as Record<string, string | null>);

/** Converts customer metaValuesById returned by the Api to better defined data types usable for input elements */
export const convertMetaValuesByIdIn = (
  metaValuesById: Record<string, TranslatedData<string>>,
  metaFields: MetaField[],
  languages: string[]
) => {
  const parsedData = {} as Record<string, MetaData>;
  metaFields.forEach(field => {
    if (!field.id || isNaN(Number(field.id.trim()))) return; // validate customer meta field id
    if (field.valueType === 'dropdown-multiple') {
      languages.forEach(lang => {
        const key = getMetaFieldKey(field.id, lang);
        const value = metaValuesById[field.id]?.[lang] || '';

        if (Array.isArray(value)) parsedData[key] = value;
        else if (typeof value === 'string')
          parsedData[key] = value.split(';').filter(x => !!x);
      });
    } else if (field.valueType === 'date') {
      languages.forEach(lang => {
        const date = metaValuesById[field.id]?.[lang];
        parsedData[getMetaFieldKey(field.id, lang)] =
          date && typeof date === 'string' ? new Date(date) : null;
      });
    } else if (isTreeMetaField(field)) {
      languages.forEach(lang => {
        const key = getMetaFieldKey(field.id, lang);
        const value = metaValuesById[field.id]?.[lang];
        if (!value) {
          return;
        }
        // Include only valid nodes
        if (Array.isArray(value)) parsedData[key] = value;
        else if (typeof value === 'string')
          parsedData[key] = value
            .split(';')
            .filter(id => validateNode(id, field));
      });
    } else {
      languages.forEach(lang => {
        parsedData[getMetaFieldKey(field.id, lang)] =
          metaValuesById[field.id]?.[lang];
      });
    }
  });
  return parsedData;
};

/**
 *  Some tags in Exifr have slight variations in their name
 *  See: https://github.com/MikeKovarik/exifr/blob/master/src/dicts/
 *
 *  Mutates @param rawMeta in-place to fill tags recognized by exiftool but named differently in exifr
 */
const convertExifrRawmeta = rawMeta => {
  /* eslint-disable */
  // IPTC fields
  fillImageMetaIfExists(rawMeta, 'By-line', 'Byline');
  fillImageMetaIfExists(rawMeta, 'Province-State', 'State');
  fillImageMetaIfExists(rawMeta, 'Country-PrimaryLocationCode', 'CountryCode');
  fillImageMetaIfExists(rawMeta, 'Country-PrimaryLocationName', 'Country');
  fillImageMetaIfExists(rawMeta, 'Caption-Abstract', 'Caption');
  fillImageMetaIfExists(rawMeta, 'Writer-Editor', 'Writer');
  // misc. fields
  fillImageMetaIfExists(
    rawMeta,
    'CreatorWorkEmail',
    'CreatorContactInfo.CiEmailWork'
  );
  fillImageMetaIfExists(rawMeta, 'CreatorCity', 'CreatorContactInfo.CiAdrCity');
  fillImageMetaIfExists(
    rawMeta,
    'CreatorCountry',
    'CreatorContactInfo.CiAdrCtry'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CreatorAddress',
    'CreatorContactInfo.CiAdrExtadr'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CreatorPostalCode',
    'CreatorContactInfo.CiAdrPcode'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CreatorWorkTelephone',
    'CreatorContactInfo.CiAdrExtadr'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CreatorWorkUrl',
    'CreatorContactInfo.CiUrlWork'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CreatorRegion',
    'CreatorContactInfo.CiAdrRegion'
  );
  fillImageMetaIfExists(
    rawMeta,
    'ImageCreatorName',
    'ImageCreator.ImageCreatorName'
  );
  fillImageMetaIfExists(
    rawMeta,
    'LocationCreatedSublocation',
    'LocationCreated.Sublocation'
  );
  fillImageMetaIfExists(
    rawMeta,
    'AdditionalModelInformation',
    'AdditionalModelInfo'
  );
  fillImageMetaIfExists(
    rawMeta,
    'ImageSuppliername',
    'ImageSupplier.ImageSupplierName'
  );
  fillImageMetaIfExists(
    rawMeta,
    'ImageSupplierID',
    'ImageSupplier.ImageSupplierID'
  );
  // these might be a single object or array of objects -> covers both cases
  // although if it contains multiple records, only first is used
  fillImageMetaIfExists(
    rawMeta,
    'CopyrightOwnername',
    'CopyrightOwner.CopyrightOwnername'
  );
  fillImageMetaIfExists(
    rawMeta,
    'CopyrightOwnername',
    'CopyrightOwner.0.CopyrightOwnername'
  );
  fillImageMetaIfExists(
    rawMeta,
    'RegistryOrganisationID',
    'RegistryId.RegOrgId'
  );
  fillImageMetaIfExists(
    rawMeta,
    'RegistryOrganisationID',
    'RegistryId.0.RegOrgId'
  );
  fillImageMetaIfExists(rawMeta, 'Licensorname', 'Licensor.LicensorName');
  fillImageMetaIfExists(rawMeta, 'Licensorname', 'Licensor.0.LicensorName');
  /* eslint-enable */
};

// util for grabbing value using dot notation and assigning it to a new property
// if key is not found, nothing happens
// mutates in-place
const fillImageMetaIfExists = (target, newProperty, dotNotationProperty) => {
  try {
    const value = dotNotationProperty.split('.').reduce((a, b) => a[b], target);
    target[newProperty] = value;
  } catch {}
};

const getEncodedString = (value: any): string => {
  let encodedValue = value;
  // process values which 1) are not strings or 2) have umlauts in them
  if (typeof value !== 'string') {
    encodedValue = JSON.stringify(value); // some have objects as value
  } else {
    // https://stackoverflow.com/a/5396742
    // Some IPTC fields are encoded into the files incorrectly as e.g. Ã¤
    // hacky way of converting these back to UTF-8
    // this fails with `URIError: malformed URI sequence` if umlauts are already present
    // (some fields are correctly encoded in images)
    try {
      encodedValue = decodeURIComponent(escape(value));
    } catch {}
  }
  return encodedValue;
};

export const canExtractImageMetadata = (file: IntermediateFileToAdd) => {
  return (
    file.type === 'image' &&
    ['jpeg', 'jpg', 'png', 'tiff'].includes(file.subtype)
  );
};

const formatMetadata = (ruleName, value, outputFormat) => {
  try {
    switch (ruleName) {
      case 'RULE_IMAGE_FORMAT_DATE_TIME':
        return `${format(new Date(value), outputFormat)}`;
      case 'RULE_IMAGE_GPS_LATITUDE':
      case 'RULE_IMAGE_GPS_LONGITUDE': {
        const direction = ruleName.includes('LATITUDE') ? 'N' : 'E';
        if (outputFormat === 'dd')
          return `${
            Number(value[0]) + Number(value[1]) / 60 + Number(value[2]) / 3600
          }°`;
        if (outputFormat === 'dms')
          return `${value[0]}° ${value[1]}' ${value[2]}" ${direction}`;
        if (outputFormat === 'dmm')
          return `${value[0]}° ${
            Number(value[1]) + 60 / Number(value[2])
          }' ${direction}`;
        return value;
      }
    }
  } catch {}
  // no cases match or try-catch is triggered -> return original
  return value;
};

/**
 * Extract EXIF / IPTC / XMP metadata from image
 * @param fields should contain image metadata field name as key and customer meta-field as value
 * See `https://github.com/MikeKovarik/exifr` for tags
 * e.g. { ByLine: '141' }
 */
export const extractImageMetadata = async (
  image: Blob,
  fields: ImageMetaToCustomerMeta,
  languages: string[],
  fieldFormatting: ImageMetaFieldFormat = {}
) => {
  const rawMetadata: Record<string, string> = await exifr.parse(image, {
    iptc: true,
    xmp: true,
  });
  if (!rawMetadata) return {};

  convertExifrRawmeta(rawMetadata);

  const partialMetaById = {};
  for (const [field, metaId] of Object.entries(fields)) {
    // find value by comparing lowercase entries
    let value = (Object.entries(rawMetadata).find(
      ([key, _]) => key.toLowerCase() === field.toLowerCase()
    ) ?? ['', ''])[1];

    // custom formatting for some fields
    if (Object.keys(fieldFormatting).includes(field) && value) {
      const formatInfo = fieldFormatting[field];
      value = formatMetadata(formatInfo.ruleName, value, formatInfo.format);
    } else {
      value = getEncodedString(value);
    }

    for (const lang of languages) {
      partialMetaById[getCustomMetaFieldKey(metaId, lang)] = value;
    }
  }

  return partialMetaById;
};

export const extractAllRawImageMetadata = async (
  image: Blob,
  flatten = false
) => {
  // return normally formatted metadata
  const meta = await exifr.parse(image, { iptc: true, xmp: true });
  if (!meta) return {};
  if (!flatten) return meta;
  // or flatten object to dot notation
  const target = {};
  // recursive function to flatten nested object and apply result to target object
  function parseObject(obj, target, prefix = '') {
    for (const key of Object.keys(obj)) {
      // call parseObject again if value is array or object
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        obj[key] !== undefined
      ) {
        parseObject(obj[key], target, `${prefix}${key}.`);
      } else {
        target[prefix + key] = getEncodedString(obj[key]);
      }
    }
  }
  parseObject(meta, target);
  return target;
};

/**
 * Given a list of Files, add metadata to file.metaById based on given @param fields
 */
export const populateImagesMetaById = async (
  images: IntermediateFileToAdd[],
  fields: ImageMetaToCustomerMeta,
  languages: string[],
  fieldFormatting: ImageMetaFieldFormat = {}
): Promise<IntermediateFileToAdd[]> => {
  return Promise.all(
    images.map(async image => {
      if (image.type !== 'image') return image;
      const metaById = await extractImageMetadata(
        image.blob,
        fields,
        languages,
        fieldFormatting
      );
      return { ...image, metaById: { ...(image.metaById ?? {}), ...metaById } };
    })
  );
};

/** Filters out properties that can't be updated */
export const filterUpdatablePropertiesById = (
  propertiesById: Record<string, string>
) =>
  Object.keys(propertiesById)
    .filter(
      id =>
        id.startsWith(DESCRIPTION_FIELD_ID) ||
        id.startsWith(PROPERTY_VALIDITY_PERIOD) ||
        id === PROPERTY_MARKED_FOR_ARCHIVING ||
        id.startsWith('nibo:archive')
    )
    .reduce((acc, id) => {
      acc[id] = propertiesById[id];
      return acc;
    }, {} as Record<string, string>);

/** Returns errors of metadata fields mapped by the field key  */
export const getErronousFields = (
  metaFields: MetaField[],
  metaById: Record<string, any>,
  languages: string[],
  defaultLanguage: string
) => {
  const erroneousFields = {} as Record<string, FieldError>;
  metaFields.forEach(field => {
    languages.forEach(lang => {
      const fieldKey = getCustomMetaKey(field.id, lang);
      // just the values for the default language are mandatory
      const error = isErroneousField(
        {
          ...field,
          name: field.namesByLang[lang],
          isMandatory: field.isMandatory && lang === defaultLanguage,
        },
        metaById[fieldKey]
      );
      if (error) erroneousFields[fieldKey] = error;
    });
    if (field.id === PROPERTY_VALIDITY_PERIOD) {
      const err = checkValidityPeriod(metaById);
      if (err)
        erroneousFields[field.id] = {
          name: field.id,
          isInvalid: true,
          errorDetail: err,
        } as FieldError;
    }
  });
  return erroneousFields;
};

/** Get all language codes which contain errors */
export function getErroneousLanguages<
  T extends { errors?: Record<string, unknown> }
>(files: T[]) {
  const erroneousLanguages = new Set<string>();
  files.forEach(file => {
    if (file.errors) {
      Object.keys(file.errors).map(metakey =>
        erroneousLanguages.add(metakey.split('_')[1])
      );
    }
  });
  return [...erroneousLanguages];
}

type NameCheckedFile = FileToAdd | FileToEdit | IntermediateFileToAdd;
/** Marks the files that have conflicting names
 *
 * Can be either used by providing files and setFiles from component or letting the hook provide those
 */
export function useNameCheckedFiles<T extends NameCheckedFile>(): [
  T[],
  React.Dispatch<React.SetStateAction<T[]>>,
  () => void
];
export function useNameCheckedFiles<T extends NameCheckedFile>(
  outerFiles: T[],
  setOuterFiles: React.Dispatch<React.SetStateAction<T[]>>,
  folderPath: string,
  folderId: string
): [T[], React.Dispatch<React.SetStateAction<T[]>>, () => void];
export function useNameCheckedFiles<T extends NameCheckedFile>(
  outerFiles?: T[],
  setOuterFiles?: React.Dispatch<React.SetStateAction<T[]>>,
  folderPath?: string,
  folderId?: string
) {
  const share = useSelector(state => state.app.share);
  const shareKey = share ? share.key.join('/') : undefined;
  const metaFields = useCustomerMetaFields(shareKey ? { shareKey } : undefined);
  const languages = useSelector(state => state.app.customer?.languages);
  const { getConflictingFiles } = useBackgroundUploadContext();

  const [innerFiles, setInnerFiles] = useState<T[]>([]);
  const [files, setFiles] = [
    outerFiles || innerFiles,
    setOuterFiles || setInnerFiles,
  ];

  // save the responses, so that we don't have to refetch the results
  const [existingIdsByPath, setExistingIds] = useState<{
    [name: string]: string | null;
  }>({});
  const [existingFilesById, setExistingFiles] = useState<{
    [id: string]: File;
  }>({});

  // Save the first instances of the names for files being edited
  // so that those won't have to be checked from server
  const originalPathsById = useRef<Record<string, string>>({});
  useEffect(() => {
    files.forEach(file => {
      const id = getFileId(file);
      if (id && !originalPathsById.current[id])
        originalPathsById.current[id] = getFilePath(file, folderPath);
    });
  }, [files.length]);

  useEffect(() => {
    setExistingIds({});
    setExistingFiles({});
  }, [folderPath, folderId]);

  // For the filenames that have changed,
  // check from server if a file with the same name already exists and update existingIdsByName
  useEffect(() => {
    const filesToCheck = files.map(file => {
      const filename = getFilename(file);
      const filepath = getFilePath(file);
      const id = getFileId(file);
      const parentPath =
        'concrete' in file
          ? file.concrete?.path.substring(
              0,
              file.concrete?.path.lastIndexOf('/')
            )
          : folderPath;
      if (existingIdsByPath[filepath] !== undefined || !parentPath)
        return undefined;
      else if (id && filepath === originalPathsById.current[id])
        return { filename, parentPath, originalId: id };
      else return { filename, parentPath };
    });
    // Batch updaing existingIdsByName for perf reasons
    executeBatchedOperations(
      {
        operations: filesToCheck
          .filter(filterUndefined)
          .map(({ filename, parentPath, originalId }) => async () => {
            const existingId =
              originalId ||
              (await queryExistingFileId(parentPath, filename, shareKey));
            return { filename, parentPath, existingId };
          }),
        windowSize: 5,
      },
      {
        onAllReady: results =>
          setExistingIds(ids => ({
            ...ids,
            ...results.reduce(
              (a, res) =>
                'error' in res
                  ? a
                  : {
                      ...a,
                      [`${res.parentPath}/${res.filename}`]: res.existingId,
                    },
              {}
            ),
          })),
      }
    );
  }, [files.map(file => getFilePath(file, folderPath)).join(' '), folderPath]);

  // Fetch the meta data of existing files
  // TODO: would be nice if the queryExistingFileId would optionally also return the file
  useEffect(() => {
    // Just fetch files that haven't been previously fetched
    const fetchableIds = Object.values(existingIdsByPath).filter(
      (id): id is string => !!id && !existingFilesById[id]
    );
    // Update existingFilesById only once everything has been fetched
    executeBatchedOperations(
      {
        operations: fetchableIds.map(id => async () => ({
          file: await readFile({
            id,
            params: { include: 'meta,basic,validity,info' },
          }),
          fileId: id,
        })),
        windowSize: 5,
      },
      {
        onAllReady: results =>
          setExistingFiles(files => ({
            ...files,
            ...results.reduce(
              (a, res) =>
                'error' in res || res.file.removed
                  ? a
                  : { ...a, [res.fileId]: res.file },
              {}
            ),
          })),
      }
    );
  }, [existingIdsByPath]);

  useEffect(() => {
    if (!metaFields.length || !languages?.length) return;
    setFiles(files =>
      files.map(file => {
        const filepath = getFilePath(file, folderPath);
        const existingId = existingIdsByPath[filepath];
        if (existingId === undefined) return file;
        const existingFile = existingId
          ? existingFilesById[existingId]
          : undefined;
        const existingMetaById = existingFile
          ? convertMetaValuesByIdIn(
              existingFile.metaValuesById,
              metaFields,
              languages
            )
          : undefined;
        const existingDescriptions = existingFile
          ? flattenDescriptionsByLang(existingFile.descriptionsByLang)
          : undefined;
        const existingInfo = existingFile?.instructionsByLang
          ? flattenLocalizedObject(existingFile.instructionsByLang, 'nibo:info')
          : undefined;

        const parentId =
          'concrete' in file ? file.concrete?.parentId : folderId;
        const filename = getFilename(file);
        const conflictingName = {
          existing: !!existingId && existingId !== getFileId(file),
          filepicker:
            files.filter(f => getFilePath(f, folderPath) === filepath).length >
            1,
          queue: parentId
            ? getConflictingFiles(filename, parentId).filter(
                conflict =>
                  !files.some(
                    file =>
                      'preview' in file && file.preview === conflict.preview
                  )
              ).length > 0
            : false,
        };

        return {
          ...file,
          metaById: mergeMetadata((file as IntermediateFileToAdd).metaById, {
            ...existingMetaById,
            ...existingDescriptions,
            ...existingInfo,
          }),
          exists: !!existingId,
          existingId: existingId || undefined,
          conflictingName: Object.values(conflictingName).some(x => x)
            ? conflictingName
            : undefined,
        };
      })
    );
  }, [
    files.map(file => getFilePath(file, folderPath)).join(' '),
    existingIdsByPath,
    existingFilesById,
    metaFields,
    languages,
  ]);

  /** After files have been updated in the server, this hooks state is
   * most likely stale and should be reset */
  const resetInternalState = () => {
    setExistingIds({});
    setExistingFiles({});
    originalPathsById.current = {};
  };

  return [files, setFiles, resetInternalState] as const;
}

const getFilename = (file: FileToEdit | FileToAdd) =>
  file.affixedName ?? file.name;
const getFilePath = (file: FileToEdit | FileToAdd, folderPath?: string) => {
  const parentPath =
    'concrete' in file
      ? file.concrete?.path.substring(0, file.concrete?.path.lastIndexOf('/'))
      : folderPath;
  return `${parentPath}/${getFilename(file)}`;
};
const getFileId = (file: FileToEdit | FileToAdd) =>
  'concrete' in file && (file as FileToEdit).concrete?.id;

/** Adds the affixed name property to files
 * by appending the customer configured suffix and prefix to the file's name */
export function useAffixedFiles<T extends FileToAdd | FileToEdit>() {
  const [files, setFiles] = useState<T[]>([]);

  const customerConfig = useSelector(state => state.app.customer?.configById);
  const defaultLanguage = useSelector(
    state => state.app.customer?.defaultLanguage
  );

  const affixFiles = useCallback(
    (files: T[]) =>
      files.map(file => {
        let nameParts: string[] = [];
        if (file.name) {
          nameParts = file.name.split('.');
        } else if ('namesByLang' in file && defaultLanguage) {
          nameParts = file.namesByLang[defaultLanguage]?.split('.') ?? [];
        }
        const extension = nameParts.slice(-1)[0] ?? '';
        const fileName = nameParts.slice(0, -1).join('.');
        const prefix = customerConfig?.['upload.file.name.prefix'] ?? '';
        const suffix = customerConfig?.['upload.file.name.suffix'] ?? '';
        return {
          ...file,
          affixedName: `${prefix}${fileName}${suffix}${
            extension ? `.${extension}` : extension
          }`,
        };
      }),
    [customerConfig]
  );

  const affixedFiles = affixFiles(files);

  const affixedSetFiles = useCallback(
    (value: React.SetStateAction<T[]>) => {
      setFiles(files =>
        typeof value === 'function' ? value(affixFiles(files)) : value
      );
    },
    [setFiles, affixFiles]
  );

  return [affixedFiles, affixedSetFiles] as const;
}

/** Fetches the folder with inherited meta, properties and layout
 * and converts those to default meta field values
 *
 * NOTE: Returns the folder so that we don't hopefully
 * fetch the same folder without the inherited meta in
 * the component and risking those values being overwritten
 * TODO: Manage files/folders saved in redux in a way that
 * won't overwrite properties that aren't included in the fetch */
export const useInheritedFolder = (folderId: string) => {
  const metaFields = useCustomerMetaFields();
  const languages = useSelector(state => state.app.customer?.languages);
  const { data: folderData } = useGetFolderQuery({
    id: folderId,
    include: 'meta',
    params: { inheritmeta: true, inheritlayout: true },
  });
  const folder = folderData?.removed ? undefined : folderData;

  const defaultMetaById = useMemo(() => {
    if (!folder || !metaFields || metaFields.length === 0 || !languages)
      return {};
    let defaultMeta = metaFields.reduce((acc, field) => {
      languages.forEach(
        lang =>
          (acc[getMetaFieldKey(field.id, lang)] =
            field.valueType !== 'date' &&
            field.valueType !== 'dropdown-multiple'
              ? ''
              : null)
      );
      return acc;
    }, {});
    if (Number(folder.inheritedCustomUploadLayout) === 7)
      defaultMeta = { ...defaultMeta, ...folder.inheritedMetaById };
    if (Number(folder.customUploadLayout) === 7)
      defaultMeta = {
        ...defaultMeta,
        ...flattenMetaValuesById(folder.inheritableMetaById),
      };
    return convertMetaValuesByIdIn(
      getMetaValuesById(defaultMeta, languages),
      metaFields,
      languages
    );
  }, [folder, metaFields, languages]);

  const defaultPropertiesById = useMemo(() => {
    if (!folder || !languages) return {};
    let defaultProperties: Record<string, MetaData> = languages.reduce(
      (a, lang) => ({ ...a, [`${DESCRIPTION_FIELD_ID}_${lang}`]: '' }),
      {}
    );
    if (Number(folder.inheritedCustomUploadLayout) === 7)
      defaultProperties = {
        ...defaultProperties,
        ...extractInheritedProperties(folder.inheritedPropertiesById),
      };
    if (Number(folder.customUploadLayout) === 7)
      defaultProperties = {
        ...defaultProperties,
        ...extractInheritedProperties(folder.propertiesById),
      };
    return defaultProperties;
  }, [folder, languages]);

  return { defaultMetaById, defaultPropertiesById, folder };
};

const extractInheritedProperties = (properties: Record<string, string>) =>
  Object.keys(properties).reduce((acc, key) => {
    if (key.includes('inherited-'))
      acc[key.replace('inherited-', '')] = properties[key];
    return acc;
  }, {});

/** Check if two names would cause a conflict on backend */
export const collidingNames = (name1: string, name2: string) =>
  name1.toLowerCase() === name2.toLowerCase() ||
  name1.toLowerCase().normalize() === name2.toLowerCase().normalize();

/**
 * Merge metaById -values, if a field in the `metaById` is empty-ish
 * use the default value from `def` (if it exists)
 */
export const mergeMetadata = (
  def: Record<string, MetaData> = {},
  metaById: Record<string, MetaData> = {}
) => {
  const merged = {} as Record<string, MetaData>;
  for (const key in { ...def, ...metaById }) {
    const val = metaById[key];
    const defVal = def[key] || val;
    const isEmpty = !val || (val instanceof Array && val.every(e => !e));
    merged[key] = isEmpty ? defVal : val;
  }
  return merged;
};

/** Separates meta data to propertiesById and metaById compatible objects */
export const splitPropertiesAndMetaData = (metaData: Record<string, unknown>) =>
  Object.entries(metaData).reduce(
    (acc, [key, value]) => {
      if (key.startsWith('custom:meta-field')) {
        return { ...acc, data: { ...acc.data, [key]: value } };
      } else if (key.startsWith(INSTRUCTION_FIELD_ID)) {
        return {
          ...acc,
          instructionsProps: { ...acc.instructionsProps, [key]: value },
        };
      } else {
        return { ...acc, properties: { ...acc.properties, [key]: value } };
      }
    },
    {
      data: {} as Record<string, any>,
      properties: {} as Record<string, any>,
      instructionsProps: {} as Record<string, any>,
    }
  );

/** Transforms a translated data object's keys into ones compatible with API
 *
 * For example:
 * ```
 *  {
 *    fi: 'Hei moi',
 *    en: 'Hi yo',
 *  }
 * ```
 * would transform into
 * ```
 *  {
 *    'custom:meta-field-123_fi': 'Hei moi',
 *    'custom:meta-field-123_en': 'Hi yo',
 *  }
 * ```
 */
export function translatedDataToMetaData(
  data: Record<Lang, MetaData>,
  metaFieldId: string
) {
  return Object.entries(data).reduce(
    (acc, [lang, value]) => ({
      ...acc,
      [metaFieldId.startsWith(DESCRIPTION_FIELD_ID) ||
      metaFieldId.startsWith(INSTRUCTION_FIELD_ID)
        ? `${metaFieldId}_${lang}`
        : getCustomMetaFieldKey(metaFieldId, lang)]: value,
    }),
    {}
  );
}

/** Copies and localizes the given value to all given languages */
export function copyValueToAllLanguages<T extends MetaData>(
  field: MetaField,
  value: T,
  lang: Lang,
  languages: Lang[]
): Record<Lang, T> {
  const options =
    isDropdownMetaField(field) && getLangValue(field.optionsByLang, lang);

  return languages.reduce((acc, language) => {
    let localizedValue = value;
    if (options) {
      const localizedOptions = getLangValue(field.optionsByLang, language);
      localizedValue = (
        Array.isArray(value)
          ? value
              .map(x => localizedOptions[options.indexOf(x)])
              .filter(x => x !== undefined)
          : (typeof value === 'string' &&
              localizedOptions[options.indexOf(value)]) ||
            ''
      ) as T;
    }
    return {
      ...acc,
      [language]: localizedValue,
    };
  }, {} as Record<Lang, T>);
}

/** Copies and localizes the given value to all given languages having object keys compatible with API */
export function copyValueToAllLanguagesAsMetaData(
  field: MetaField,
  languages: string[],
  lang: string,
  value: any
) {
  return translatedDataToMetaData(
    copyValueToAllLanguages(field, value, lang as Lang, languages as Lang[]),
    field.id
  );
}

/** Checks if the type of the meta field is such that the values should be copied across languages */
export const isFieldInstantCopy = (field: MetaField) =>
  field.valueType === 'dropdown' ||
  field.valueType === 'dropdown-multiple' ||
  field.valueType === 'date' ||
  field.valueType === 'tree' ||
  field.valueType === 'tree-multiple';

/** Converts the customUploadLayout property to specific upload layout properties */
export const getUploadLayoutConfig = (folder?: File): LayoutConfig => {
  if (!folder) return {};
  const helper = (configValues: number[]) =>
    configValues.includes(Number(folder.customUploadLayout)) ||
    (!Number(folder.customUploadLayout) &&
      configValues.includes(Number(folder.inheritedCustomUploadLayout)));
  return {
    excludeDescription: helper([2, 5]),
    mandatoryDescription: helper([4, 7]),
    excelActions: helper([5, 6, 7]),
  };
};

/** Constructs the message to show if we have a file with conflicting name
 * according to customer and user settings. */
export const getConflictingFilesMessage = (
  files: FileToAdd[],
  uploadMode: UploadMode | undefined,
  userRights: UserRights | null,
  isMassEditEnabled: boolean | undefined,
  updateMetadata: boolean
) => {
  if (files.some(file => file.conflictingName?.existing))
    return getExistingFileMessage(
      uploadMode,
      userRights,
      isMassEditEnabled,
      updateMetadata
    );
  if (files.some(file => file.conflictingName?.queue))
    return t`A file with the same name is already being uploaded.`;
  if (files.some(file => file.conflictingName?.filepicker))
    return t`Multiple files with the same name.`;
  return undefined;
};

function getExistingFileMessage(
  uploadMode: UploadMode | undefined,
  userRights: UserRights | null,
  isMassEditEnabled: boolean | undefined,
  updateMetadata: boolean
) {
  let message = t`A file with the same name exists.`;

  if (uploadMode !== undefined) {
    if (uploadMode === UploadMode.UPDATE) {
      if (userRights?.MATERIAL_VERSION_MANAGE) {
        message += ' ' + t`You can update the file or create a new version`;
      } else {
        message += ' ' + t`The file will be updated`;
      }
    } else if (uploadMode === UploadMode.VERSION) {
      message += ' ' + t`A new version will be created`;
    }

    if (!updateMetadata) {
      message += ', ' + t`but the metadata for this file won't be updated`;
    }
    message += '.';
  }

  if (isMassEditEnabled) {
    message +=
      ' ' + t`If you want to edit metadata, switch to mass upload view.`;
  }

  return message;
}

type SanitizedMetaData = Record<string, string | null>;

type EligibleFiles = FileToAdd | IntermediateFileToAdd | UploadFile;

type UseUploadFileArgs = {
  targetFolderId: string;
};

type UploadFileArgs<T> = {
  file: T;
  sanitizedMetaData: SanitizedMetaData;
  setFileUploadStatus: (file: T, uploadStatus: UploadStatus) => void;
  shouldInvalidateCache?: boolean;
  updateIfExists?: boolean;
  metaFields?: MetaField[];
  onUploadProgress?: (file: T) => (percent: number) => void;
  params?: {
    shareKey?: string;
    rename?: boolean;
  };
};

type UploadFileChunkMutation = ReturnType<typeof useUploadFileChunkMutation>[0];

type UploadFileChunkArgs = {
  file: EligibleFiles;
  targetFolderId: string;
  uploadFileChunk: UploadFileChunkMutation;
  chunkSize: number;
  ignoreLastChunk: boolean;
  onUploadProgress?: (file: EligibleFiles) => (percent: number) => void;
  cancelRef?: React.MutableRefObject<() => void>;
};
export const uploadFileByChunk = async ({
  file,
  targetFolderId,
  uploadFileChunk,
  chunkSize,
  ignoreLastChunk,
  onUploadProgress,
  cancelRef,
}: UploadFileChunkArgs) => {
  const chunkFileName = uuidv4();
  const remain = 1024; // 1KB
  if (chunkSize < 5) chunkSize = 5; // MB
  chunkSize = chunkSize * 1024 * 1000; // B
  const fileSize = Math.ceil(file.blob.size);
  if (fileSize <= remain) return { chunkFileName, chunkSize, offset: 0 };
  const totalChunks = Math.ceil(file.blob.size / chunkSize);
  const recountLastChunk =
    ignoreLastChunk && fileSize - chunkSize * (totalChunks - 1) > remain;
  let chunkNumber = 0;
  let offset = 0;
  const total =
    ignoreLastChunk && !recountLastChunk ? totalChunks - 1 : totalChunks;
  while (chunkNumber < total) {
    if (recountLastChunk && chunkNumber + 1 === total) {
      chunkSize = fileSize - offset - remain; // left small part of last chunk for upload with multipart submit that should not suport cancel
    }
    try {
      const uploadFileChunkPromise = uploadFileChunk({
        folderId: targetFolderId,
        chunkFileName,
        chunkNumber,
        totalChunks,
        blob: file.blob.slice(offset, offset + chunkSize),
        mimetype: `${file.type}/${file.subtype}`,
        progressCallback: onUploadProgress?.(file),
      });
      if (cancelRef) cancelRef.current = uploadFileChunkPromise.abort;
      await uploadFileChunkPromise.unwrap();
    } catch (e) {
      if (e?.name === 'AbortError') {
        throw e; // throw AbortController exceptio
      }
      throw e; // other exception
    }
    offset = offset + chunkSize;
    if (recountLastChunk && chunkNumber + 1 === total) {
      chunkSize = Math.max(chunkSize, remain);
    }
    chunkNumber++;
  }
  return { chunkFileName, chunkSize, offset };
};

/** Helper hook for processing a singular file upload */
function useUploadFile({ targetFolderId }: UseUploadFileArgs) {
  const [createFileMultipart] = useCreateFileMultipartMutation();
  const [updateFileMultipart] = useUpdateFileMultipartMutation();
  const [uploadFileChunk] = useUploadFileChunkMutation();
  const [getExistingFile] = useLazyGetExistingFileQuery();
  const customer = useSelector(state => state.app.customer);
  const { data: targetFolder } = useGetFolderQuery({ id: targetFolderId });
  const targetFolderPath = !targetFolder?.removed
    ? targetFolder?.concrete?.path
    : undefined;
  const invalidateFolder = useActions(invalidateFile);

  const [uploadCancelled, setUploadCancelled] = useState(false);

  /** During the upload process we might fire two API calls, so we store the
   * currently applicable promise abortion function here */
  const cancelRef = useRef(() => {});

  /**
   * For file uploads, we have four distinct flows:
   *  1. We're uploading a file via upload form, and we always want to create a new file.
   *  2. We're uploading a file for the first time
   *  3. We're uploading a pre-existing file and overwriting the old
   *  4. We're uploading a pre-existing file and creating a new version
   *
   * TODO: use only multipart requests to simplify the flows. Currently
   * the logic displayed herein is quite convoluted
   */
  const uploadFile = <T extends EligibleFiles>({
    file,
    sanitizedMetaData,
    setFileUploadStatus,
    shouldInvalidateCache = true,
    updateIfExists = false,
    metaFields,
    onUploadProgress,
    params,
  }: UploadFileArgs<T>) => {
    // We wrap the whole thing in a promise so it can be awaited when used, as
    // `FileReader` doesn't provide us with awaitable methods
    return new Promise<{ id: string } | undefined>(resolve => {
      onUploadProgress?.(file)(0);
      setUploadCancelled(false);

      const reader = new FileReader();
      const filename = ('affixedName' in file && file.affixedName) || file.name;

      // Callback to start uploading the file after `FileReader` has loaded it from
      // the client's filesystem
      reader.onloadend = async () => {
        try {
          // Don't upload already uploaded files
          if (file.uploadStatus === UploadStatus.UPLOADED) {
            resolve(undefined);
            return;
          }

          const { data, properties, instructionsProps } =
            splitPropertiesAndMetaData(sanitizedMetaData);
          let fileId: string | undefined;

          // Start uploading
          setFileUploadStatus(file, UploadStatus.UPLOADING);
          // Always create a new file when using upload forms
          if (!file.exists || params?.shareKey?.startsWith('i')) {
            const { chunkFileName, chunkSize, offset } =
              await uploadFileByChunk({
                file,
                targetFolderId,
                uploadFileChunk,
                chunkSize: Number(customer?.configById['upload.chunk.size.mb']),
                ignoreLastChunk: true, // last chunk will be uploaded with multipart data
                onUploadProgress,
                cancelRef,
              });
            const createFilePromise = createFileMultipart({
              folderId: targetFolderId,
              filename,
              blob: file.blob.slice(offset, offset + chunkSize), // last chunk
              metaById: convertMetaByIdOut(data, metaFields), // data
              propertiesById: filterUpdatablePropertiesById(properties), // properties
              infoByLang: mapKeys(instructionsProps, key =>
                key.replace(`${INSTRUCTION_FIELD_ID}_`, '')
              ),
              mimetype: `${file.type}/${file.subtype}`,
              chunkFileName,
              params,
              progressCallback: onUploadProgress?.(file),
            });
            fileId = (await createFilePromise.unwrap())?.id;
          } else if (!targetFolderPath) {
            throw new Error();
          } else {
            const existingFilePromise = getExistingFile({
              filename,
              parentPath: targetFolderPath,
            });
            cancelRef.current = existingFilePromise.abort;
            fileId = (await existingFilePromise.unwrap())?.id ?? undefined;
            if (fileId === undefined || fileId !== file.existingId) {
              throw new Error();
            }
            const replaceOnlyFile = (file.exists && !updateIfExists) ?? false;
            const { chunkFileName, chunkSize, offset } =
              await uploadFileByChunk({
                file,
                targetFolderId,
                uploadFileChunk,
                chunkSize: Number(customer?.configById['upload.chunk.size.mb']),
                ignoreLastChunk: true,
                onUploadProgress,
                cancelRef,
              });
            const updateFilePromise = updateFileMultipart({
              id: fileId,
              filename,
              blob: file.blob.slice(offset, offset + chunkSize), // last chunk
              versioning: file.versioning,
              onlyFile: replaceOnlyFile,
              metaById: convertMetaByIdOut(data, metaFields),
              propertiesById: filterUpdatablePropertiesById(properties),
              chunkFileName,
              mimetype: `${file.type}/${file.subtype}`,
              progressCallback: onUploadProgress?.(file),
            });
            await updateFilePromise.unwrap();
          }

          setFileUploadStatus(file, UploadStatus.UPLOADED);
          resolve({ id: fileId });
        } catch (e) {
          if (e?.name === 'AbortError') {
            // `AbortController` specific error
            setFileUploadStatus(file, UploadStatus.CANCELLED);
          } else {
            setFileUploadStatus(file, UploadStatus.ERROR);
          }
          resolve(undefined);
        } finally {
          // Reset the request abortion ref no matter what happened
          cancelRef.current = () => {};
          if (shouldInvalidateCache) invalidateFolder(targetFolderId);
        }
      };

      reader.readAsDataURL(file.blob);
    });
  };

  const cancelUpload = () => {
    cancelRef.current();
    setUploadCancelled(true);
  };

  /** Reset the internal state of the hook for a clean slate */
  const reset = () => {
    cancelRef.current = () => {};
    setUploadCancelled(false);
  };

  return {
    uploadFile,
    cancelUpload,
    uploadCancelled,
    reset,
  };
}

type AddFilesArgs<T> = {
  targetFolderId: string;
  files: T[];
  setFiles: React.Dispatch<React.SetStateAction<T[]>>;
  metaFields: MetaField[];
  metaData?: Record<string, MetaData>;
  updateIfExists?: boolean;
  onUploadProgress?: (file: T) => (percent: number) => void;
  params?: {
    shareKey?: string;
    rename?: boolean;
  };
};

/**
 * Custom hook returning handles for uploading multiple files in succession.
 * If each file should receive the same metadata, pass it as an argument to
 * this hook. Otherwise files are assumed to have their own metadata (for
 * example, via `addMultiple`).
 */
export function useAddFiles<T extends EligibleFiles>({
  targetFolderId,
  files,
  setFiles,
  metaData,
  metaFields,
  updateIfExists = false,
  params,
}: AddFilesArgs<T>) {
  const { uploadFile, cancelUpload, uploadCancelled, reset } = useUploadFile({
    targetFolderId,
  });

  const invalidateFolder = useActions(invalidateFile);

  // We store the index of the file currently being uploaded and use it
  // to trigger starting new ones
  const [uploadIndex, setUploadIndex] = useState<number | null>(null);
  const [uploadedIds, setUploadedIds] = useState<string[]>([]);

  const setFileUploadStatus = (file: FileToAdd, uploadStatus: UploadStatus) =>
    setFiles(files =>
      files.map(x => (x.name === file.name ? { ...x, uploadStatus } : x))
    );

  const [uploadPercentage, setPercentage] = useState<number | undefined>(
    undefined
  );

  const onUploadProgress = (file: T) => (percentage: number) => {
    setPercentage(percentage);
    setFiles(files =>
      files.map(x =>
        x.name === file.name
          ? {
              ...x,
              uploadProgress: percentage,
            }
          : x
      )
    );
  };

  const [currentMetaData, setCurrentMetadata] = useState(metaData);

  /** If needed, fresh version of metadata can be supplied here to be used in upload */
  const startUpload = (freshMetaData?: Record<string, MetaData>) => {
    reset();

    if (freshMetaData) {
      setCurrentMetadata(freshMetaData);
    } else {
      setCurrentMetadata(metaData);
    }

    // Update all files' upload statuses to pending unless they were cancelled
    // or already uploaded in a previous attempt
    setFiles(files =>
      files.map(x => ({
        ...x,
        uploadStatus:
          x.uploadStatus !== UploadStatus.UPLOADED &&
          x.uploadStatus !== UploadStatus.CANCELLED
            ? UploadStatus.WAITING
            : x.uploadStatus,
      }))
    );
    // Setting the upload index will start the upload process
    setUploadIndex(0);
  };

  // Whenever `uploadIndex` changes, we try to upload the corresponding
  // file of our array. This effect recursively updates the index after
  // each upload attempt, so it should try each file in the array once
  // if not manually interrupted
  useEffect(() => {
    // We want to be able to await the file upload so we wrap the process
    // as `useEffect` won't allow async callbacks
    const iteration = async () => {
      if (
        uploadIndex !== null &&
        !uploadCancelled &&
        files.length > uploadIndex
      ) {
        // If we're uploading from mass edit view, each file has their own
        // metadata
        const file = files[uploadIndex];
        let sanitizedMetaData: SanitizedMetaData;
        if (currentMetaData) {
          // Don't update meta or properties if updating existing file from single view
          // Only the file contents should be updated
          sanitizedMetaData = !file.exists
            ? convertMetaByIdOut(currentMetaData ?? {}, metaFields)
            : {};
        } else {
          sanitizedMetaData = convertMetaByIdOut(
            (file as IntermediateFileToAdd).metaById ?? {}
          );
        }

        const uploadedId = await uploadFile({
          file,
          sanitizedMetaData,
          setFileUploadStatus,
          shouldInvalidateCache: false,
          metaFields,
          updateIfExists,
          onUploadProgress,
          params,
        });
        if (uploadedId) setUploadedIds(ids => [...ids, uploadedId.id]);

        setUploadIndex(index => (index ?? 0) + 1);
      } else {
        // Iteration has stopped, we should refetch the parent folder now
        // so that it won't have stale data. We want to run this even if
        // the user canceled uploading, as some uploads might still have
        // succeeded.
        invalidateFolder(targetFolderId);
      }
    };

    iteration();
  }, [uploadIndex]);

  const resetHookState = () => {
    reset();
    setUploadIndex(null);
    setUploadedIds([]);
    setCurrentMetadata(metaData);
    setPercentage(undefined);
  };

  const uploadDone =
    files.length > 0 &&
    files.every(
      x =>
        x.uploadStatus === UploadStatus.ERROR ||
        x.uploadStatus === UploadStatus.UPLOADED
    );

  const uploadFailed =
    uploadDone && files.some(x => x.uploadStatus === UploadStatus.ERROR);

  const uploading =
    !uploadDone && !uploadFailed && !uploadCancelled && uploadIndex !== null;

  const batchStatus = uploading
    ? UploadStatus.UPLOADING
    : uploadFailed
    ? UploadStatus.ERROR
    : uploadCancelled
    ? UploadStatus.CANCELLED
    : uploadDone && files.every(x => x.uploadStatus === UploadStatus.UPLOADED)
    ? UploadStatus.UPLOADED
    : undefined;

  return {
    startUpload,
    /** The percentage of the current file being uploaded. 0-100% */
    uploadPercentage,
    uploading,
    /** Cancels the current file being uploaded and stops the rest from starting */
    cancelUpload,
    /** Have all uploads either succeeded or errored */
    uploadDone,
    /** Have all uploads completed and some errored */
    uploadFailed,
    /** Was the upload manually cancelled */
    uploadCancelled,
    /** Reset the internal state of this hook */
    reset: resetHookState,
    /** List of ids for the files that were newly created */
    uploadedIds,
    /** A status combaining the uploading, uploadDone, uploadFailed and uploadCancelled flags */
    batchStatus,
  };
}

/** Provides the same functionality as useAddFiles but the files
 * are uploaded in a background process.
 */
export function useBackgroundAddFiles(
  props: Parameters<typeof useAddFiles<FileToAdd>>[0]
) {
  const { upsertUploadBatch, getBatch } = useBackgroundUploadContext();

  const { startUpload, ...addRest } = useAddFiles(props);

  const [batchId, setBatchId] = useState<string>();

  const batch = batchId ? getBatch(batchId) : undefined;
  const batchFiles = batch?.params.files;

  // Update the original files so that the upload page can display the progress
  useEffect(() => {
    if (batchFiles) props.setFiles(batchFiles);
    else {
      props.setFiles(files =>
        files.map(x => ({
          ...x,
          uploadStatus:
            x.uploadStatus === UploadStatus.UPLOADED
              ? x.uploadStatus
              : undefined,
          uploadProgress: undefined,
        }))
      );
    }
  }, [batchFiles]);

  const handleStartUpload = (freshMetaData?: Record<string, MetaData>) => {
    const files = props.files.map(x => ({
      ...x,
      uploadStatus:
        x.uploadStatus === UploadStatus.UPLOADED
          ? x.uploadStatus
          : UploadStatus.WAITING,
    }));
    setBatchId(
      upsertUploadBatch(
        {
          params: {
            ...props,
            metaData: freshMetaData ?? props.metaData,
            files,
          },
        },
        batchId
      )
    );
  };

  const batchStatuses = batch?.methods
    ? {
        uploading:
          batch.methods.batchStatus === UploadStatus.UPLOADING ||
          batch.methods.batchStatus === UploadStatus.WAITING,
        uploadDone:
          batch.methods.batchStatus === UploadStatus.UPLOADED ||
          batch.methods.batchStatus === UploadStatus.ERROR,
        uploadFailed: batch.methods.batchStatus === UploadStatus.ERROR,
        uploadCancelled: batch.methods.batchStatus === UploadStatus.CANCELLED,
      }
    : undefined;

  const reset = () => {
    addRest.reset();
    setBatchId(undefined);
  };

  return {
    ...addRest,
    ...batch?.methods,
    ...batchStatuses,
    startUpload: handleStartUpload,
    reset,
  };
}

export const checkValidityPeriod = (metaData: Record<string, MetaData>) => {
  try {
    const validity = metaData[PROPERTY_VALIDITY_PERIOD];
    let v = metaData[PROPERTY_VALIDITY_PERIOD_START] as string;
    const startDate = v ? new Date(Date.parse(v)) : null;
    v = metaData[PROPERTY_VALIDITY_PERIOD_END] as string;
    const endDate = v ? new Date(Date.parse(v)) : null;

    return startDate && endDate && endDate < startDate
      ? t`The end date must be after the start date`
      : (validity === 'true' && !startDate && !endDate) ||
        (startDate && !endDate) ||
        (!startDate && endDate)
      ? t`Set validity period from to date`
      : undefined;
  } catch (e) {
    return t`validity period error`;
  }
};

/** Helper for populating or resetting the `MetaData` of a field */
export const getInitialFieldValue = (field: {
  valueType: MetaField['valueType'];
}): string | string[] | null => {
  if (field.valueType === 'date') {
    return null;
  } else if (field.valueType === 'dropdown-multiple') {
    return [];
  } else {
    return '';
  }
};

export const getFieldValue = (
  metaById: Record<string, MetaData>,
  field: MetaField,
  currentLanguage: LanguageCode
) => {
  return (
    metaById?.[getCustomMetaKey(field.id, currentLanguage)] ||
    getInitialFieldValue(field)
  );
};
